import Vue from "vue";
import Vuex, { ActionContext } from "vuex";
import createPersistedState from "vuex-persistedstate";
import scale from "./modules/scale";
import log from "./modules/log";
import allflex from "./modules/allflex";
import processing from "./modules/processing";
import drafting from "./modules/drafting";
import dataManager from "./modules/data-manager";
import upload from "./modules/upload";
//import hash from "object-hash";
import sortingGates from "./modules/sorting-gates";
import popup from "./modules/popup";
import user from "./modules/user";
import settings from "./modules/settings";
import sync from "./modules/sync";
import * as Models from "@gigalot/data-models";
import { v4 as uuid } from "uuid";
import lodash from "lodash";
import * as moment from "moment";
import "moment/locale/en-gb";
import * as packageJson from "../../package.json";
import { checkTagAgainstAnimalsInBatch, checkTagAgainstAnimalsInDatabase, checkTagDate } from "@/helpers/program-tag";
import * as RtcClient from "@gigalot/rtc-client";
import { createClient } from "@/helpers/graphql-ws-rtc-adapter";
import { rtcEmitter } from "@/main";
import { visualGcpMatch } from "@/helpers/sgtin";
import certificates from "./modules/certificates";
import deepmerge from "deepmerge";
import { fetchWithTimeout } from "@/helpers/fetch-with-timeout";
import LogRocket from 'logrocket';

////////
// LogRocket
LogRocket.init('3gwxd3/gigalot');

export function identify(user:any){
// This is an example script - don't forget to change it!
LogRocket.identify(user.uid, {
    name: user.displayName,
    email: user.email,
    // Add your own custom user variables here, ie:
    subscriptionType: 'pro'
  });
}

// LogRocket
////////


const vuexMapFields = require("vuex-map-fields");
//const getField = vuexMapFields.getField;
//const updateField = vuexMapFields.updateField;
//const createHelpers = vuexMapFields.createHelpers;

export const { mapFields, mapMultiRowFields } = vuexMapFields.createHelpers({
  getterType: "getField",
  mutationType: "updateField"
});

Vue.use(Vuex);

type EnvType = "production" | "staging" | "testing" | "undetermined";

let productionUrl: string = "https://processing.gigalot.co.za";
let stagingUrl: string = "https://processing.gigalot.systems";
let localCloudUrl: string = "http://localhost:8085"; //processing-app-cloud-deploy run locally

async function graphQl(o: {
  jwt: string,
  url?: string,
  query: string,
  variables: any,
  useRtc: boolean;
  onprogress?: ((num: number, progress: number, total: number) => void);
  timeout?: number;
}) {
  if (!o.useRtc) {
    if (!o.url) throw Error("url required if useRtc is false");
    let options: RequestInit = {
      method: "POST",
      //headers: { "Content-Type": "application/json", Accept: "application/json", Authorization: "Bearer " + o.jwt },
      headers: { "Content-Type": "application/json", Accept: "application/json", Authorization: "Bearer " + o.jwt },
      body: JSON.stringify({ query: o.query, variables: o.variables })
    };
    let response = await fetch(o.url, options);

    if (response.ok) {
      let json = await response.json();

      return json;
    } else {
      let errorJson = await response.json();
      let s = "";
      for (let error of errorJson.errors) {
        s += `\n${error.message}`;
      }
      throw Error("response not ok: " + s);
    }
  } /*useRtc*/ else {
    const graphQLRetryPrematureConnection = async () => {
      const attemptGraphQL = () => new Promise((resolve, reject) => {
        let result: any;
        const client = createClient(reject, o.onprogress);
        if (!client) return; //reject is called if no data channel found (causing client to return as undefined)
        client.subscribe(
          {
            query: o.query,
            variables: o.variables,
          },
          {
            next: (data: any) => {
              if (data.errors) for (const err of data.errors) console.error(err.message);
              if (data.data === null && data.errors?.length)
                reject(data.errors.map((e: { message: string; }) => e.message).join("\n"));
              result = data;
            },
            error: (err) => { console.error("ERROR REJECT: ", err); reject(err); },
            complete: () => resolve(result)
          }
        );
      });

      let tries = 0;
      const MAX_TRIES = 3;
      let e;
      while (tries < MAX_TRIES) {
        try {
          const r = await attemptGraphQL();
          return r;
        } catch (err) {
          //ignore and try again
          e = err;
          if (`${err}`.endsWith("Message not parsable")) break;
          console.warn(`try number ${tries + 1} graphql error: ${err}`);
          tries++;
        }
      }
      throw e;
    };
    if (o.timeout === undefined) o.timeout = 5 * 60 * 1000;
    if (o?.timeout !== undefined) {
      const timeout: number = o?.timeout as number;
      const timeoutPromise = new Promise((_, reject) => { setTimeout(() => reject("request timeout"), timeout); });
      let onRtcConnectionChange: (ev: any) => void = (_) => { };
      const connectionErrorPromise = new Promise((_, reject) => {
        onRtcConnectionChange = (ev: any) => {
          console.log("onRtcConnectionChange", ev);
          if (ev === "disconnected") reject("disconnected during request");
          //rtcEmitter.off("connectionEvent", onRtcConnectionChange);
        };
        rtcEmitter.on("connectionEvent", onRtcConnectionChange);
      });

      const p = await Promise.race([graphQLRetryPrematureConnection(), timeoutPromise, connectionErrorPromise]);
      rtcEmitter.off("connectionEvent", onRtcConnectionChange);
      return p;
    } else {
      return await graphQLRetryPrematureConnection();
    }
  }
}

async function hslTag(url: string) {
  //let url = context.getters["settings/processTagUrl"]();
  console.log("hslTag url: " + url);
  //let response = await fetch(url);
  let response = await fetchWithTimeout(url);
  if (response.ok) {
    let returnedTag = await response.text();
    returnedTag = returnedTag.replace(/"/g, ""); //remove any double quotes that might be surrounding the string
    if (/.*error.*/.test(returnedTag.toLowerCase())) {
      throw Error("HSL: " + returnedTag);
    } else if (returnedTag) {
      const sgtin96Regex = /^urn:epc:tag:sgtin-96:0\.[0-9]{10}\.0[0-9]{2}\.[0-9]{12}$/gm;
      if (!sgtin96Regex.test(returnedTag)) {
        throw Error("Invalid sgtin given: " + returnedTag);
      }
      return returnedTag;
    } else {
      throw Error("Empty tag returned");
    }
  } else {
    throw Error("HTTP response not ok");
  }
}

class State {
  constructor() {
    if (window.origin === productionUrl || window.origin.startsWith("https://gigalot-cloud-processing-app--preview")) {
      console.log("Production environment detected.");
      this.environment = "production";
    } else if (window.origin === stagingUrl) {
      console.log("Staging (cloud) environment detected.");
      this.environment = "staging";
    } else {
      console.log("Testing (local) environment detected.");
      this.environment = "testing";
    }
  }

  environment: EnvType;
  moment: any = moment;
  storage: { [uid: string]: { [guid: string]: any; }; } = {};
  lightDarkMode: "light" | "dark" = "dark";
  currentSelectBatchSetupProcessingFunction?: Models.ProcessingFunction;
  appBarText: string = "";
  version: string = packageJson.version;
  location?: { type: string; name: string; guid: string; }; //typically the feedlot's node / local server
  processingStation?: Models.ProcessingStation;
  hardwareAvailability?: { allflex: boolean; };
  connectedToProxy: boolean = false;
  navFuncs: { save?: () => void; back?: () => void; } = {};
  rtcSignalling: "disconnected" | "local" | "cloud" = "disconnected";
  isSingleInstance: boolean = true;
}

export default new Vuex.Store<State>({
  state: new State(),
  mutations: {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }
    */
    updateField: vuexMapFields.updateField,
    lightDarkMode(state: any, payload: "light" | "dark") {
      state.lightDarkMode = payload;
    },
    version(state: any) {
      state.version = packageJson.version;
    },
    connectedToProxy(state: any, payload: boolean) {
      state.connectedToProxy = payload;
    },
    navFuncs(state: State, payload: { save?: () => void; back?: () => void; }) {
      state.navFuncs = payload;
    },
    rtcSignalling(state: State, payload: "disconnected" | "local" | "cloud") {
      state.rtcSignalling = payload;
    },
    isSingleInstance(state: State, payload: boolean) {
      state.isSingleInstance = payload;
    }
  },
  actions: {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */
    async onAppCreated(context: any) {
      context.commit("rtcSignalling", "disconnected");
      context.commit("isSingleInstance", true);
      await context.dispatch("dataManager/onAppCreated");
      await context.dispatch("processing/onAppCreated");
      await context.dispatch("scale/onAppCreated");
      await context.dispatch("sync/onAppCreated");
      context.commit("upload/setIsBusyUploading", false);
    },
    async graphQl(
      context: ActionContext<State, any>,
      o: {
        gql: string;
        variables?: { [key: string]: any; };
        jwt: string;
        destination?: "cloud" | "office-server" | "processing-server";
        onprogress?: ((num: number, progress: number, total: number) => void);
        timeout?: number;
      }
    ) {
      if (!o.gql) throw Error("No GraphQL query given.");
      if (!o.jwt) o.jwt = await context.dispatch("user/getOfflineIdToken", undefined, { root: true });
      let url;
      if (o.destination === "cloud") url = `${context.getters["urlCloud"]()}/processing`;
      else if (o.destination === "processing-server") url = `https://${context.getters["settings/hslAddress"]()}:9000/processing-proxy`;

      if (url) console.log(`GraphQL url: ${url}`);

      /*
      Don't use WebRTC if query is for the cloud or the proxy
      */
      if (o.destination === "cloud" || o.destination === "processing-server") {
        return await graphQl({ jwt: o.jwt, url: url, query: o.gql, variables: o.variables, useRtc: false });
      } else if (o.destination === "office-server") {
        if (context.state.rtcSignalling === "disconnected") throw Error("Not connected");
        return await graphQl({ jwt: o.jwt, url: undefined, query: o.gql, variables: o.variables, useRtc: true, onprogress: o.onprogress, timeout: o.timeout });
      } else throw Error("Invalid destination");

    },
    async programTag(context: any, o: { busyProcessing: boolean; }) {
      //We should first read the tag and check if tag is in batch or in database, and then only write if OK.
      if (!o) throw Error("programTag(): parameters required but none given.");

      //Read tag without programming
      let oldSgtin = await hslTag(context.getters["settings/desktopReaderReadTagUrl"]());

      //Check if tag can be used, exceptions will be thrown if not.
      if (o.busyProcessing) await checkTagAgainstAnimalsInBatch(oldSgtin);
      await checkTagAgainstAnimalsInDatabase(oldSgtin);

      //If code reaches here, then no exception thrown. So tag must be OK to use.

      let newSgtin = await hslTag(context.getters["settings/processTagUrl"]());

      //Make extra sure that no one swapped out tags inbetween process.
      if (!visualGcpMatch(oldSgtin, newSgtin)) throw Error("Tag read different to written tag.");

      //checkTagDate(newSgtin); //Mobiboxes don't all have the correct date and time set.
      return newSgtin;
    },
    async readTag(context: any) {
      return hslTag(context.getters["settings/readTagUrl"]());
    }
  },
  getters: {
    /*
    getter(state: ScanState, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
    getField: vuexMapFields.getField,
    urlNode(state: State) {
      return () => {
        // switch (state.environment) {
        //   case "production":
        //     return "https://pi.gigalot.systems:7766"; //must be https
        //   case "staging":
        //     return "https://pi.gigalot.systems:7766"; //must be https
        //   case "testing":
        //     //return nodeUrl; //can be http
        //     return "https://pi.gigalot.systems:7766";
        //   //return "http://localhost:7766";
        //   case "undetermined":
        //   default:
        //     throw Error("No environment detected! (Expected to be production, staging, or testing)");
        // }
        return "https://pi.gigalot.systems:7766";
      };
    },
    urlCloud(state: State) {
      return () => {
        switch (state.environment) {
          case "production":
            return "https://europe-west1-gigalot-cloud.cloudfunctions.net/processingAppResolver";
          case "staging":
            return "https://europe-west1-gigalot-testing.cloudfunctions.net/processingAppResolver";
          case "testing":
            return "http://localhost:5001/gigalot-testing/europe-west1/processingAppResolver";
          case "undetermined":
          default:
            throw Error("No environment detected! (Expected to be production, staging, or testing)");
        }
      };
    },
    urlCert(state: State) {
      return () => {
        switch (state.environment) {
          case "production":
            return "https://europe-west1-gigalot-cloud.cloudfunctions.net/getProxyCertificate";
          case "staging":
            return "https://europe-west1-gigalot-testing.cloudfunctions.net/getProxyCertificate";
          case "testing":
            return "http://127.0.0.1:5001/gigalot-testing/europe-west1/getProxyCertificate";
          case "undetermined":
          default:
            throw Error("No environment detected! (Expected to be production, staging, or testing)");
        }
      };
    },
    dark(state: any) {
      return () => {
        return state.lightDarkMode === "dark";
      };
    }
  },
  modules: {
    scale,
    allflex,
    processing,
    drafting,
    dataManager,
    upload,
    sortingGates,
    popup,
    user,
    settings,
    log,
    sync,
    certificates
  },
  plugins: [createPersistedState(
    // {
    //   reducer: (state: any) => {
    //     return state;
    //   }
    // }
    {
      reducer: (state: any) => {
        const ret = (Object.keys(state) as string[]).reduce((a, i) => {
          if (i === "processing") {
            // Exclude currentAnimal
            const ret1 = {
              "processing": (Object.keys(state["processing"]) as string[]).reduce((a, i) => {
                if (i === "currentAnimal") return a; // return inner accumulator unmodified
                else return deepmerge(a, { [i]: state["processing"][i] });
              }, {/* start empty accumulator*/ })
            };
            return deepmerge(a, ret1);
          } else if (i === "currentHospitalResult") {
            return a; // return accumulator unmodified
          } else {
            return deepmerge(a, { [i]: state[i] });
          }
        }, {/* start empty accumulator*/ }
        );
        // console.log("!!! REDUCER !!! START");
        // console.log(JSON.stringify(ret, undefined, 4));
        // console.log("!!! REDUCER !!! END");
        return ret;
      }
    }
  )]
});